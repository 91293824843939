import {React, useCallback, useContext, useEffect, useState} from "react";
import { Link, useOutletContext } from "react-router-dom";
import { motion } from "framer-motion";
import { OrderContext } from "./order";
import axiosProvider from "../../core/axios";
import { toast } from "react-toastify";

const Step2 = () => {
    const [order, setOrder, setError] = useContext(OrderContext)
    const [data, setData] = useState(null);
    const [saveOrder] = useOutletContext()

    const fetchData = useCallback(async () => {
        await axiosProvider.get("/shop/formulas/").then((response) => {
            setData(response.data)
        }).catch((error) => {
            setError({
                error : "Erreur de récupération de données", 
                message:"Une erreur c'est produite lors de la récupération des données."
            })
        });
    }, [setError]);

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const checkBox = (formula) =>{
        if(formula.uuid === order.step2.formula.uuid){
            return order.step2.formula.uuid
        }
        return ""
    }

    const checkStep = (order) => {
        if(Object.keys(order.step2.formula).length === 0){
            return false;
        }
        return true;
    };

    const handleNext = () => {
        if (checkStep(order)) {
            saveOrder(2);
        } else {
            toast.error("Étape 2 incomplète : Veuillez sélectionner une formule.");
            window.scrollTo(0, 0)
        }
    }

    return (
        <>
            {data ? (
                <>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ type: "linear", duration: 0.2 }}
                        className="container bg-white"
                    >
                        <div className="spacer"></div>
                        <h2 className="big-text bold-text black-text pad-b-xl">
                        Les formules
                        </h2>
                        <div className="grid-3 pad-b-xl">
                            {data.formulas.map((formula, i) => {
                                return (
                                    <div className="grid-item" key={i}>
                                        <label htmlFor={formula.uuid} className={order.step2.formula.uuid === formula.uuid ? "fancy-card-od fancy-card-checked": "fancy-card-od fancy-card-check"} >
                                            <Link to={`/formules/${formula.slug}`} className="fancy-card-head fancy-card-head-blue">
                                                <p className="big-text bold-text white-text">{formula.pack}</p>
                                            </Link>
                                            <div className="fancy-card-content">
                                                <div className="flex-col-center">
                                                    <p className="large-text regular-text blue-text">Jusqu'à 3h du matin</p>
                                                </div>
                                                <div className="flex-col-center">
                                                    <ul className="fancy-item-list marg-b-m">
                                                        {formula.items.map((item, i) => {
                                                            return (
                                                                <li className="fancy-card-item" key={i}>
                                                                    <div className="circle-dot circle-blue"></div>
                                                                    <p className="large-text regular-text r-black-text pad-l-s">
                                                                       <b>{item.quantity}</b> {item.title}
                                                                    </p>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="fancy-card-footer pad-b-l">
                                                <p className="medium-text regular-text r-black-text pad-b-s">
                                                à partir de
                                                </p>
                                                <p className="big-text bold-text blue-text pad-b-m">{formula.price} €</p>
                                                <Link className="btn btn-small btn-bordered btn-current" to={`/formules/${formula.slug}`}>Voir plus</Link>
                                            </div>
                                        </label>
                                        <input type="radio" name="formule" id={formula.uuid} value={checkBox(formula)} className="fancy-card-checker" onChange={() => setOrder({...order , step2: {...order.step2, formula: formula}})}/>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="flex-row-between pad-b-xl">
                        <a href={"/demande/etape-1"} className="btn btn-default btn-large btn-cream">Précédent</a>
                        <div onClick={() => handleNext()} className="btn btn-default btn-large btn-current">Suivant</div>
                        </div>
                        <div className="spacer"></div>
                    </motion.div>
                </>
            ) : (
                <>
                    <div className="container-primary bg-white">
                        <div className="flex-full-center full-height">
                            <div className="icon-big">
                                <motion.svg xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 422 422"
                                animate={{rotate:[0, 360] }}
                                transition={{
                                    type: "linear",
                                    duration: 0.5, 
                                    repeat: Infinity
                                }}>
                                    <g id="loading_icon" transform="translate(4730 -3101)">
                                        <path className="loading" id="Subtraction_2" data-name="Subtraction 2" d="M436.543,212.707h0l-72.556-.1c.008-.575.012-1.179.012-1.795A128.8,128.8,0,0,0,275.063,88.184V13.247l.422.086.134.027a200.468,200.468,0,0,1,72.07,30.326,202.154,202.154,0,0,1,73.023,88.677A200.476,200.476,0,0,1,432.457,170.2a203.034,203.034,0,0,1,4.1,40.62c0,.62,0,1.255-.009,1.889Z" transform="translate(-4744.552 3094.183)" />
                                        <circle id="Ellipse_25" data-name="Ellipse 25" cx="211" cy="211" r="211" transform="translate(-4730 3101)" fill="none"/>
                                    </g>
                                </motion.svg>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
        
    );
};

export default Step2;
